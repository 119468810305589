import FeatureCard from 'components/FeatureCard';
import FeatureFlagIcon from '../../assets/feature-flag-icon.svg';
import InvoiceIcon from '../../assets/invoice-icon.svg';
import VersionIcon from '../../assets/version-icon.svg';
import styles from './styles.module.scss';
import { useContext } from 'react';
import { AuthContext } from 'contexts/auth/context';
import { useNavigate } from 'react-router';

const Home = () => {
    const { accessLevel } = useContext(AuthContext);
    const navigate = useNavigate();

    const isDev = accessLevel === 3;

    return (
        <div className={`w-100 h-100 ${styles.container}`} data-testid="container-page">
            <div className={styles.row}>
                {(accessLevel === 1 || isDev) && (
                    <FeatureCard
                        onClick={() => {
                            navigate('/gestao-funcionalidades');
                        }}
                        title="Gestão de funcionalidades"
                        color="#0045B5"
                        backgroundColor="#BCD6F0"
                        img={FeatureFlagIcon}
                    />
                )}
                {(accessLevel === 1 || isDev) && (
                    <FeatureCard
                        onClick={() => {
                            navigate('/versionamento');
                        }}
                        title="Versionamento"
                        color="#58595B"
                        backgroundColor="#FFD100"
                        img={VersionIcon}
                    />
                )}
                {([1, 2].includes(accessLevel as number) || isDev) && (
                    <FeatureCard
                        onClick={() => {
                            navigate('/canhoto-digital');
                        }}
                        title="Canhoto Digital"
                        color="#FF6900"
                        backgroundColor="#FFF5CC"
                        img={InvoiceIcon}
                    />
                )}
            </div>
        </div>
    );
};

export default Home;
