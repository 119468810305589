import { Invoice } from 'components/ExportPDFModal/types';

export interface IInvoices {
    emitente: {
        nome: string;
        codigo: string;
        cnpj?: string;
    };
    cliente: {
        nome: string;
        cnpj: string;
        endereco: string;
    };
    numeroNF: string;
    dataEmissaoNFe: string;
    dataBaixaCanhoto?: string;
    modalidadeFrete: string;
    statusCanhoto: string;
    chaveNFe: string;
    produtos: {
        nome: string;
        volume: string;
        id: string;
    }[];
    numeroPedido: string;
    nomeTransportador: string;
    codigoTransportador: string;
    serie: string;
    motorista: {
        cpf: string;
        nome: string;
    };
    dataAssinatura?: string;
    recebedor?: {
        cpf: string;
        nome: string;
        funcao: string;
    };
    geolocalizacao?: {
        latitude: string;
        longitude: string;
    };
    imagemS3?: string;
    idS3?: string;
}

export interface InvoicesBack {
    notas: IInvoices[];
    quantidade?: number;
    quantidadePaginas?: number;
}

export interface IRenderList {
    emitente: string;
    cliente: string;
    modalidadeFrete: string;
    numeroNF: string;
    dataEmissaoNFe: string;
    dataBaixaCanhoto?: string;
    statusCanhoto: string;
}

export interface IListProps {
    invoicesData: IInvoices[];
    headerTitles: string[];
    isCheckBox?: boolean;
    changePage?: (page: number) => void;
    quatityPerPage: number;
    quantityAll: number;
    quantityTotalPages?: number;
    onSelect?: (invoice: Invoice) => void;
    page: number;
    onExportPDF?: () => void;
    onExportCSV?: () => void;
    onViewInvoice?: () => void;
    csvData?: any;
    onSelectAll?: (checked: boolean) => void;
    loading: boolean;
    keyExportButton?: string;
    isSelectAll?: boolean;
    setSelectAllChecked?: (checked: boolean) => void;
    selectedListItem?: IInvoices[];
}

export const RenderList = [
    'emitente',
    'cliente',
    'dataEmissaoNFe',
    'dataBaixaCanhoto',
    'numeroNF',
    'statusCanhoto',
    'modalidadeFrete',
];
