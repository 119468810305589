import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { SnackbarProvider, closeSnackbar } from 'notistack';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router';
import theme from 'utils/global/theme/theme';
// import CustomThemeProvider from 'utils/global/theme/themeProvice'
import { ThemeProvider } from 'styled-components';
import GlobalStyles from 'utils/global/GlobalStyles';
import store from './App/store';
import Loading from './components/Loading';
import './fonts/IpirangaTextos-Bold.ttf';
import './fonts/IpirangaTextos-Light.ttf';
import './fonts/IpirangaTextos-Medium.ttf';
import './fonts/IpirangaTextos-Regular.ttf';
import './fonts/IpirangaTitulos-Bold.ttf';
import './index.css';
import reportWebVitals from './reportWebVitals';
import router from './routes';
import AuthProvider from 'contexts/auth/context';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const pca = new PublicClientApplication({
    auth: {
        clientId: process.env.REACT_APP_AZURE_CLIENT_ID ?? '83f6dfc6-1127-422e-b835-a661911dbe28', // Application (client) ID from the app registration
        authority:
            'https://login.microsoftonline.com/' + process.env.REACT_APP_AZURE_AUTHORITY ??
            '72b5f416-8f41-4c88-a6a0-bb4b91383888', // The Azure cloud instance and the app's sign-in audience (tenant ID, common, organizations, or consumers)
        redirectUri: process.env.REACT_APP_AZURE_REDIRECT_URI, // This is your redirect URI
        // redirectUri: `https://portal-ipicarga-${process.env.NODE_ENV}.ipiranga.io/login`, // This is your redirect URI
    },
    cache: {
        cacheLocation: 'localStorage',
    },
});
root.render(
    <React.StrictMode>
        <GlobalStyles />
        <ThemeProvider theme={theme}>
            <MsalProvider instance={pca}>
                <Provider store={store}>
                    <SnackbarProvider
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        style={{
                            width: '100%',
                            marginTop: '32px',
                            borderRadius: '16px',
                        }}
                        action={(snackBarId) => {
                            return (
                                <svg
                                    style={{
                                        marginRight: '8px',
                                        padding: '4px',
                                        cursor: 'pointer',
                                        width: '24px',
                                        height: '24px',
                                    }}
                                    onClick={() => closeSnackbar(snackBarId)}
                                    width="14"
                                    height="15"
                                    viewBox="0 0 14 15"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M14 1.91L12.59 0.5L7 6.09L1.41 0.5L0 1.91L5.59 7.5L0 13.09L1.41 14.5L7 8.91L12.59 14.5L14 13.09L8.41 7.5L14 1.91Z"
                                        fill="white"
                                    />
                                </svg>
                            );
                        }}
                        preventDuplicate
                        hideIconVariant
                    >
                        <Suspense fallback={<Loading />}>
                            <AuthProvider>
                                <RouterProvider router={router} />
                            </AuthProvider>
                        </Suspense>
                    </SnackbarProvider>
                </Provider>
            </MsalProvider>
        </ThemeProvider>
    </React.StrictMode>
);

reportWebVitals();
