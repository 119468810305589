import AppNavbar from 'layouts/AppNavbar';
import FeatureFlag from 'pages/FeatureFlag';
import Home from 'pages/Home';
import Invoices from 'pages/Invoices';
import Login from 'pages/Login';
import NotFound from 'pages/NotFound';
import Splash from 'pages/Splash';
import Versioning from 'pages/Versioning';
import { createBrowserRouter } from 'react-router-dom';
import ProtectedRoutes from './ProtectedRoutes';

const router = createBrowserRouter([
    {
        path: '/',
        element: <Splash />,
    },
    {
        path: '/login',
        element: <Login />,
    },
    {
        path: '/inicio',
        element: (
            <ProtectedRoutes>
                <AppNavbar title="Portal de configuração" initialMargin description="">
                    <Home />
                </AppNavbar>
            </ProtectedRoutes>
        ),
    },
    {
        path: '/gestao-funcionalidades',
        element: (
            <ProtectedRoutes levelAccess={1}>
                <AppNavbar
                    title="Gestão de Funcionalidades"
                    description="Painel de controle para dar acesso às funcionalidades por unidade operacional."
                    isGoBack
                >
                    <FeatureFlag />
                </AppNavbar>
            </ProtectedRoutes>
        ),
    },
    {
        path: '/versionamento',
        element: (
            <ProtectedRoutes levelAccess={1}>
                <AppNavbar
                    title="Versionamento"
                    description="Histórico de versões do aplicativo, podendo controlar quais são obrigatórias"
                    isGoBack
                >
                    <Versioning />
                </AppNavbar>
            </ProtectedRoutes>
        ),
    },
    {
        path: '/canhoto-digital',
        element: (
            <ProtectedRoutes levelAccess={[1, 2]}>
                <AppNavbar
                    title="Canhoto Digital"
                    description="Consulte ou extraia as informações do Canhoto Digital que desejar."
                    isGoBack
                >
                    <Invoices />
                </AppNavbar>
            </ProtectedRoutes>
        ),
    },

    {
        path: '*',
        element: <NotFound />,
    },
]);

export default router;
