import React from 'react';

import { Modal } from 'react-bootstrap';
import { OrderStatusModalProps } from './types';
import SaveCancelButtons from 'components/SaveCancelButtons';
import InputFloatingLabel from 'components/InputFloatingLabel';
import InputDate from 'components/InputDate';
import dayjs, { Dayjs } from 'dayjs';

import styles from './styles.module.scss';
import { revertInvoiceStatus } from 'Functions';

export const OrderStatusModal = ({ isOpen, handleClose }: OrderStatusModalProps) => {
    const [formData, setFormData] = React.useState({
        transporter: {
            value: '',
            error: '',
        },
        initialDate: {
            value: null as Dayjs | null,
            error: '',
        },
        endDate: {
            value: null as Dayjs | null,
            error: '',
        },
    });

    const [isLoading, setIsLoading] = React.useState(false);

    const handleSave = async () => {
        const validations = Object.values(formData).reduce(
            (acc, curr) => acc && !!curr.value,
            true
        );

        if (!validations) return;

        setIsLoading(true);

        try {
            await revertInvoiceStatus({
                transporter: formData.transporter.value,
                initialDate: formData.initialDate.value?.format('YYYY-MM-DD') || '',
                finalDate: formData.endDate.value?.format('YYYY-MM-DD') || '',
            });
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
            handleClose();
        }
    };

    return (
        <Modal centered show={isOpen} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Alteração Canhoto</Modal.Title>
            </Modal.Header>
            <Modal.Body
                style={{ display: 'flex', flexDirection: 'column', gap: 12, padding: '48px 24px' }}
            >
                <fieldset style={{ display: 'flex', width: '100%', gap: 12 }}>
                    <InputDate
                        label="Data início"
                        value={formData.initialDate.value}
                        onChange={(date, validationError) => {
                            setFormData((prev: any) => ({
                                ...prev,
                                initialDate: { value: date, error: validationError },
                            }));
                        }}
                        maxDate={dayjs(new Date())}
                    />
                    <InputDate
                        label="Data final"
                        value={formData.endDate.value}
                        onChange={(date, validationError) => {
                            setFormData((prev: any) => ({
                                ...prev,
                                endDate: { value: date, error: validationError },
                            }));
                        }}
                        maxDate={dayjs(new Date())}
                    />
                </fieldset>
                <InputFloatingLabel
                    type="text"
                    id="Transportador"
                    label="Transportador"
                    value={formData.transporter.value}
                    onChange={(e) => {
                        let value = e.target.value;
                        value = value.replace(/[^0-9]/g, '');
                        setFormData((prev) => ({ ...prev, transporter: { value, error: '' } }));
                    }}
                />
            </Modal.Body>
            <Modal.Footer>
                <div>
                    <SaveCancelButtons
                        areaClassName={styles.buttonsHolder}
                        cancelFunc={handleClose}
                        saveFunc={handleSave}
                        loading={isLoading}
                    />
                </div>
            </Modal.Footer>
        </Modal>
    );
};
